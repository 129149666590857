/* Cadastro.css */

.meme-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  color: white;
}

button:hover {
  background-color: #21a1f1;
}

.error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
}

.success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
}

.success-message.hide {
  opacity: 0;
}

/* Estilos específicos para dispositivos móveis */
@media (max-width: 768px) {
  .meme-form {
    padding: 10px; /* Ajuste o padding para dispositivos móveis */
  }

  .form-group input {
    width: calc(100% - 10px); /* Ajuste o tamanho do campo para incluir padding menor */
    padding: 8px; /* Adicione padding interno para os campos de entrada */
  }

  button[type="submit"] {
    width: 100%; /* Torna o botão de envio responsivo */
  }
}
