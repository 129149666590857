.App-header {
  background-color: #282c34;
  min-height: 100px;
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Divide o cabeçalho em 12 colunas */
  align-items: center;
  padding: 0 20px;
  position: relative;
  font-size: calc(30px + 2vmin);
  color: white;
}

.App-logo {
  grid-column: 1; /* Atribui a primeira coluna para a logo */
  width: 150px; /* Defina a largura desejada para a logo */
}

h1 {
  grid-column: 2 / span 10; /* Atribui 10 colunas para o texto, começando da segunda coluna */
  margin: 0; /* Remova margens padrão do h1 */
  text-align: center; /* Centraliza o texto horizontalmente */
  font-family: 'Luckiest Guy', cursive;
}

@media (max-width: 768px) {
  .App-header {
    font-size: calc(10px + 2vmin);
  }

}