.timeline {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #DCDCDC;
  width: 90%; /* Ajuste conforme necessário */
  max-width: 800px; /* Ajuste conforme necessário */
}

.image-container {
  grid-column: 1 / span 12; /* A imagem ocupa todas as 12 colunas */
  position: relative;
  padding: 10px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.image-container p {
  font-size: 34px;
  font-weight: bold;
  margin: 10px 0;
  font-family: 'Luckiest Guy', cursive;
  text-align: left;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .titulo {
    font-size: 40px;
    font-weight: bold;
    margin: 10px;
    font-family: 'Luckiest Guy', cursive;
    text-align: left;
    cursor: pointer;
}

.header .autor {
    font-size: 20px;
    margin: 10px;
    text-align: right;
    color: gray;
}

/* Contêiner principal para os elementos */
.like-container {
  grid-column: 1 / span 12; /* A imagem ocupa todas as 12 colunas */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribui os itens uniformemente */
  width: 100%; /* Garante que o contêiner ocupe toda a largura disponível */
  margin-right: 10px;
}

/* Estilo para a data de cadastro */
.cadastro_data {
  flex: 1; /* Permite que o elemento cresça e encolha conforme necessário */
  text-align: left;
}

/* Estilo para o botão de like */
.do_like {
  flex: 1;
  text-align: center;
}

.do_like img {
  width: 50px; /* Ajuste o tamanho conforme necessário */
  height: auto; /* Mantém a proporção da imagem */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
}

/* Estilo para o número de likes */
.likes {
  flex: 1;
  text-align: right;
}


@media (max-width: 768px) {
  .timeline-item {
    grid-template-columns: repeat(12, 1fr);
  }

  .image-container {
    grid-column: 1 / span 12;
  }

  .like-container {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);
  }

  .do_like {
    grid-column: 1 / span 12;
  }

  .likes {
    grid-column: 1 / span 12;
    text-align: center;
  }

  .header .autor {
      font-size: 15px;
  }

  .header .titulo {
      font-size: 30px;
  }

  .do_like img {
    width: 40px;
  }

  .cadastro_data {
    font-size: 12px;
  }
}
