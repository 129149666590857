.Header-nav {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
}

.Header-link {
  color: white;
  text-decoration: none;
  margin-left: auto;
  margin-right: 20px;
  font-size: 1.2rem;
}

.Header-link:hover {
  text-decoration: underline;
}
